import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { RequireAuth } from './routes/RequireAuth';
import { RequireLogin } from './routes/RequireLogin';
import SpinnerLoader from './components/utils/SpinnerLoader';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

// react lazy pages

const Login = lazy(() => import('./components/auth/Login'));
const GameRouter = lazy(() => import('./routes/GameRouter'));
const HomeRouter = lazy(() => import('./routes/HomeRouter'));
const RootLayout = lazy(() => import('./routes/RootLayout'));
const Register = lazy(() => import('./components/auth/Register'));
const Profile = lazy(() => import('./components/profile/Profile'));
const AllGames = lazy(() => import('./components/games/AllGames'));
const FindWord = lazy(() => import('./components/games/FindWord'));
const TestWords = lazy(() => import('./components/games/TestWords'));
const VerifyToken = lazy(() => import('./components/auth/VerifyToken'));
const WordInImage = lazy(() => import('./components/games/WordInImage'));
const SimpleRootLayout = lazy(() => import('./routes/SimpleRootLayout'));
const UsersRating = lazy(() => import('./components/otherpages/UsersRating'));
const ForgotPassword = lazy(() => import('./components/auth/ForgotPassword'));
const RandomPosition = lazy(() => import('./components/games/RandomPosition'));

function App() {

  // react query options

  const queryClient = new QueryClient();

  // routers options

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<RootLayout />}>
        <Route index element={<HomeRouter />} />
        <Route path='gamers_rating' element={<UsersRating />} />
        <Route path='login' element={
          <RequireLogin>
            <Login />
          </RequireLogin>
        } />
        <Route path='register' element={
          <RequireLogin>
            <Register />
          </RequireLogin>
        } />
        <Route path='forgot-password' element={
          <RequireLogin>
            <SimpleRootLayout />
          </RequireLogin>
        }>
          <Route index element={<ForgotPassword />} />
          <Route path='verify' element={<VerifyToken />} />
        </Route>
        <Route path='games' element={<GameRouter />}>
          <Route index element={<AllGames />} />
          <Route path='find-word/:id' element={<FindWord />} />
          <Route path='test-words/:id' element={<TestWords />} />
          <Route path='find-image/:id' element={<WordInImage />} />
          <Route path='flip-card/:id' element={<RandomPosition />} />
        </Route>
        <Route path='profile' element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        } />
      </Route>
    )
  )

  return (
    <Suspense fallback={<SpinnerLoader full={true} />}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
